<template>
  <div>
    <div class="d-flex">
      <v-tabs
          v-model="tab"
          align-with-title
      >
        <v-tab
            v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>

      </v-tabs>
      <v-btn type="file"
             @click="handleFileImport"
             color="primary"
      >Upload
      </v-btn>
      <input
          ref="uploader"
          class="d-none"
          type="file"
          @change="onFileChanged"
      >
    </div>


    <v-tabs-items
        v-model="tab">
      <v-tab-item
          v-for="tab in tabs" :key="tab">
        <PastesContainer :type="tab"/>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar bottom right :value="uploading" :timeout="-1">
      <div class="d-flex align-center" style="gap: 1rem">
        <v-progress-circular indeterminate/>
        <span>
          Uploading
        </span>
      </div>
    </v-snackbar>

    <v-overlay :value="dragOverlay" z-index="100" @dragover="handleDrag" @dragleave="handleDragEnd" @drop="handleDrop">
      Release to upload file
    </v-overlay>
  </div>
</template>

<script>

import PastesContainer from '@/components/Paste/PastesContainer'

export default {
  name: 'Pastes',
  components: {
    PastesContainer,
  },

  data: () => ({
    pastes: null,
    dragOverlay: false,
    uploading: false,
    tab: null,
    tabs: [
      'Images',
      'Videos',
      'Files',
    ],
  }),
  mounted() {
    document.addEventListener('dragenter', this.handleDragStart)
  },
  beforeDestroy() {
    document.removeEventListener('dragenter', this.handleDragStart)
  },
  methods: {
    handleFileImport() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.upload(e.target.files[0])
    },
    handleDragStart(e) {
      e.preventDefault()

      this.dragOverlay = true
    },
    handleDrag(e) {
      e.preventDefault()
    },
    handleDragEnd() {
      this.dragOverlay = false
    },
    handleDrop(e) {
      e.preventDefault()

      console.debug(e)

      this.dragOverlay = false

      const file = e.dataTransfer.items[0].getAsFile()

      this.upload(file)
    },
    upload(file) {
      const formData = new FormData()
      formData.append('file', file)

      this.uploading = true
      this.$store.state.ky.post('paste/upload', {
        body: formData,
      }).json()
          .then((paste) => {
            this.$router.push({
              name: 'PasteDetail',
              params: {
                code: paste.hashId,
                paste: paste,
              },
            })
          })
    },
  },
}
</script>

<style scoped>

</style>
