<template>
  <div>
    <input type="file" ref="file" name="filename">
    <v-btn @click="upload">Upload</v-btn>
  </div>
</template>

<script>
export default {
  name: "Upload",
  methods: {
    upload() {
      const file = this.$refs.file
      let formData = new FormData();
      formData.append("file", file.files[0]);
      this.$store.state.ky.post('paste/upload', {
        body: formData
      });
    }
  }

}
</script>

<style scoped>

</style>