<template>
  <v-container>
    <v-row>
      <v-col
          v-for="paste in pastes"
          :key="paste.hashId"
          cols="4"
      >
        <v-card height="200" elevation="3"
                @click="openDetails(paste)">

          <div>
            <div v-if="paste.type === 'video'">
              <v-chip small color="blue" class="video-label">Video</v-chip>
              <v-icon size="75" class="video-icon">mdi-arrow-right-drop-circle-outline</v-icon>
            </div>
            <div v-if="paste.type === 'file'" class="d-flex justify-center">
              <v-icon size="150">mdi-file</v-icon>
            </div>
            <div v-else>
              <v-img height="150" :src="`${storage}/thumbnails/${paste.thumbnail}`"/>
            </div>
          </div>


          <div class="d-flex container">
            <div class="item">
              {{ getAgo(paste) }} ago
            </div>
            <div class="item">
              <div v-if="paste.createdByUser.userName">
                {{ paste.createdByUser.userName }}
              </div>
              <div v-else>
                Guest
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <div v-if="pastes.length === 0">
        Nothing found.
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { URL_STORAGE } from '@/lib/constants'
import { GetAgoDate } from '@/lib/time'

export default {
  name: "PastesContainer",
  props: {
    type: String,
  },
  data: () => ({
    pastes: null,
  }),
  mounted() {
    this.$store.state.ky.get(`paste/${this.type}/1`).json().then(res => {
      this.pastes = res
    })
  },
  methods: {
    openDetails(paste) {
      this.$router.push({
        name: 'PasteDetail',
        params: {
          code: paste.hashId,
          paste: paste,
        },
      })
    },
    getAgo(paste) {
      return GetAgoDate(new Date(paste.createdAt))
    },
  },
  computed: {
    storage() {
      return URL_STORAGE
    },
  },
}
</script>

<style scoped>

/*.container {*/
/*  elevation: higher;*/
/*}*/

.video-icon {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute !important;
  /* width: 364px; */
  /* height: 125px; */
  z-index: 10
}

.video-label {
  margin: 5px 5px 0 0;
  left: auto;
  right: 0;
  position: absolute;
  z-index: 10;
}

.item {
  flex: 1;
  text-align: center;
}
</style>